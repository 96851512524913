import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";
import bee from '../../assets/icons/bee.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Gravatar from 'react-gravatar'
import axios from 'axios';
require('dotenv').config()

// import classes from './header.module.css';

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    position: "absolute",
    width: "100vw",
    height: "86px",
    left: "0px",
    top: "0px",
    background: "#FFFFFF",
    boxshadow: "0px 2px 15px rgba(0, 0, 0, 0.03)",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 72px 0 72px",
    gap: "55%",
    width: "85%",
    height: "86px",
    background: "#FFFFFF",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "2%",
    width: "20vw",
    height: "32px",

   
  },
  titleFrame: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flexstart",
    padding: "0px",
    // gap: "1%",

    width: "75%",
    height: "22px",


    /* Inside auto layout */
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
  title1: {
    width: "20vw%",
    height: "22px",

    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "22px",
    color: "#1A122B",
    flex: "none",
    order: "0",
    flexGrow: "0",
  },
  title2: {
    width: "60vw%",
    height: "22px",

    fontFamily: 'Open Sans',
    fontStyle: "normal",
    fontWeight: "700",
    fontsize: "1rem",
    lineHeight: "22px",

    /* identical to box height, or 138% */

    /* Accent */
    color: "#1A122B",


    /* Inside auto layout */
    flex: "none",
    order: "1",
    flexGrow: "0",
  },
}));

const Header = () => {
  const { header, toolbar, logo, titleFrame, title1, title2 } = useStyles();

    const displayDesktop = () => {

      const [data, setData] = useState([]);
      const[email, setEmail] = useState('');

      const [open, setOpen] = useState(false);
      let navigate = useNavigate();
    
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

      useEffect(() => {
        async function getUserData() {
          // setIsLoading(true);
          var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/lync/user`,
            headers: {
              'Content-Type': 'application/json',
             }
          };
          axios(config)
            .then((res) => {
              // setIsLoading(false);
              // console.log(res.data.data);
              setData(res.data.data);
              setEmail(res.data.data.email);
              
    
            })
            .catch((error) => {
              // setIsLoading(false);
              console.error(error)
            })
        }
        getUserData();
      }, []);

      // let emailAvatar = email;
      // let email_hash = CryptoJS.MD5(emailAvatar).toString(CryptoJS.enc.Hex);
      // let avatar = 'https://www.gravatar.com/avatar/${email_hash}?s=180&d=mp';
      
      const logoutHandler = () => {
        // localStorage.removeItem("lync_token");
        // localStorage.removeItem("notification_token");
        // navigate("/");
            // setIsLoading(true);
            var config = {
              method: 'post',
              url: `${process.env.REACT_APP_BASE_URL}/lync/logout`,
              headers: {
                'Content-Type': 'application/json',
                }
            };
            axios(config)
              .then((res) => {
                localStorage.removeItem("lync_token");
                  localStorage.removeItem("notification_token");
                // setIsLoading(false);
                console.log(res.data.data);
                if(res.data.status==='success'){
                  navigate("/");
                  console.log('logout successful');
                  

                  // localStorage.setItem("lync_token", res.data.data.lync_token);
                  // localStorage.setItem("notification_token", res.data.data.notification_token);
                  // console.log(localStorage.getItem("notification_token"));
                  
                }
  
              })
              .catch((error) => {
                console.error(error)
              })
          }
   

      return <Toolbar className={toolbar}>
        <div className={logo}>
          <img src={bee} alt='bee icon' />
          <div className={titleFrame}>
            <Typography className={title1}>Lync</Typography>
            <Typography className={title2}>buzz</Typography>
          </div>
        </div>
        <div style={{width:'20%', color:'black', display:'flex', flexDirection:'row', alignItems:'center', gap:'15vw%'}}>
          <div style={{display:'flex', flexDirection:'row', width:'70%', alignItems:'center', gap:'5%', marginLeft:'10%'}}>
            <Gravatar email={email} size={50} rating="pg" default="mp" style={{borderRadius:"50%"}}/>
            <Typography>{data.first_name + " " + data.last_name}</Typography>
          </div>
          <div style={{width:'15%', alignItems:'center', paddingTop:'2%', paddingLeft:'5%'}}>
            <LogoutIcon onClick={handleClickOpen} sx={{cursor:"pointer"}}/>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">
            {"Confirm"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to logout ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus >Cancel</Button>
            <Button onClick={logoutHandler} >
              Ok
            </Button>
          </DialogActions>
          </Dialog>
        </div>
      </Toolbar>;
      };
      
      return (
        <header>
          <AppBar className={ header }>{displayDesktop()}</AppBar>
        </header>
      );
}

export default Header;