import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import Wrapper from '../../components/card';
import { TextField, FormGroup, FormControl, InputAdornment, IconButton, Button } from '@mui/material';
import { Card, CardContent, Box, CircularProgress } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CardHeader } from '@material-ui/core';
import CloseIcon from '../../components/closeIcon';
import axios from 'axios';
require('dotenv').config()

const theme = createTheme({
    palette: {
      primary: {
        main: '#20123A',
      },
      secondary: {
        main: '#F0F0F0',
      },
    },
  });

  const useStyles = makeStyles(() => ({
      root: {
        "& .MuiFormLabel-root":{
            display:'inline-flex',
           alignItems:'center'
        }
      }
    }));


const Login = () => {

    localStorage.removeItem("lync_token");
    localStorage.removeItem("notification_token");

    const {root} = useStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let navigate = useNavigate();

    const emailHandler = (event) =>{
        setEmail(event.target.value);
    }

    const passwordHandler = (event) =>{
        setPassword(event.target.value);
    }


    const validateEmail = (e) => {
        if (!email.includes('@')) {
            setValidEmail(true);
        } else {
            setValidEmail(false); 
        }
    }

    const validatePassword = (e) => {
        if(!password.length>0){
            setValidPassword(true);
            setErrorMessage("Password is required")
        }   
        else{
            setValidPassword(false);
        }
    }

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    async function loginHandle(event) {
        setIsLoading(true);
        event.preventDefault(); 
        let data = {
            "email" : email,
            "password" : password
        };  
        var config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/lync/login`,
        data : data
        };
        axios(config)
        .then((res) => {
            setIsLoading(false);
            // console.log(res.data);
            if(res.data.status==='success'){
                // console.log('login successful');
                localStorage.setItem("lync_token", res.data.data.lync_token);
                localStorage.setItem("notification_token", res.data.data.notification_token);
                navigate("/notification/lists");
            }
        })
        .catch((error) => {
            setIsLoading(false);
            setFormValid(true);

            console.error(error)
            console.log('not created');
        })
    }

    return(
        // <Wrapper>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                width="400px"
                margin="auto 40%"
            >
                <Card 
                    sx={{ position:'relative', width:'100%', padding:'6%', display:'flex', flexDirection:'column' }}
                >
                    <div style={{display:'flex', alignItems:'flex-start'}}>
                        <img src={require("../../assets/lyncdefault.svg")} alt="logo" />
                    </div>
                    <CardHeader
                        // component={Typography}
                        title={"Login"}
                        // subheader={"Login"}
                    />
                    
                    <CardContent>
                        <form onSubmit={loginHandle}>
                            <FormControl sx={{ gap: 2, width:'100%' }} variant="outlined">
                            {formValid && 
                                <div style={{display:'flex', backgroundColor:'#e66', color:'#FFF', width:'100%', flexDirection:'row', gap:'45%'}}>
                                    <p style={{paddingLeft:'2%', fontSize:'0.8rem'}}>Incorrect credentials</p>
                                <CloseIcon sx={{marginTop:'5%', cursor:'pointer'}} onClick={()=>setFormValid(false)}/></div>
                                }
                                <FormGroup sx={{ gap: 2 }}>
                                    <TextField 
                                        className={ root }
                                        variant="outlined"
                                        label={
                                            <Fragment>
                                                <AccountCircle fontSize="small" /> Email
                                               
                                            </Fragment>
                                        }
                                        onChange={emailHandler}
                                        value={email}
                                        // required={true}
                                        onBlur={validateEmail}
                                        error={validEmail}
                                        helperText={validEmail ? 'Please enter a valid Email' : ' '}
                                    />

                                    <TextField
                                        className={ root }
                                        label={
                                            <Fragment>
                                                <LockIcon fontSize="small"/>
                                                Password
                                            </Fragment>
                                        }
                                        variant="outlined"
                                        type={showPassword ? "text" : "password"} 
                                        value={password}
                                        onChange={passwordHandler}
                                        onBlur={validatePassword}
                                        error={validPassword}
                                        helperText={validPassword ? errorMessage : ' '}
                                        // required={true}
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                </FormGroup>
                                    <ThemeProvider theme={theme}>
                                        <Button  
                                            sx={{width:'30%', alignSelf:'flex-end', gap:'10%'}} 
                                            variant='contained' 
                                            color='primary' 
                                            type="submit" 
                                        >
                                            Login 
                                            {isLoading && <CircularProgress size={15} style={{color:'#FFFFFF'}}/>}
                                        </Button> 
                                    </ThemeProvider>
                            </FormControl>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        // </Wrapper>
        
       
    )
}

export default Login;