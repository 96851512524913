import React, { useState, useEffect } from 'react';
import classes from '../../components/commonStyle/createNotification.module.css';
import { Button, TextField } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { CircularProgress, Tab } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { TabContext, TabList, TabPanel } from '@mui/lab'
// import moment from 'moment';
import * as FiIcons from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Wrapper from '../../components/card';
import axios from 'axios';
import Posts from './list';
import Filter from './filterModal';
require('dotenv').config()

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#4B2AF1',
//     },
//     secondary: {
//       main: '#1A122B',
//     },
//     tertiary: {
//       main: '#acabb0',
//     },
//   },
//   typography: {
//     allVariants: {
//       fontFamily: 'Open Sans',
//       fontStyle: 'normal',
//       fontWeight: '400',
//       fontSize: '14px',
//       lineHeight: '22px',
//       textTransform: "unset !important",
//     },
//   },
// });


const NotificationListView = () => {

  const [isLoading, setIsLoading] = useState(false);

  const [notificationType, setNotificationType] = useState([]);
  const [data, setData] = useState([]);
  const [totalMessages, setTotalMessages] = useState('');
  const [typeFilter, setTypeFilter] = useState('0');
  const [searchTerm, setsearchTerm] = useState('');

  const [branch, setBranch] = useState([]);
  const [group, setGroup] = useState([]);
  const [ name, setName ] = useState('');
  const [ gname, setGname ] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [page, setPage] = useState(1);

  

  useEffect(() => {
    async function getNotificationType() {
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/notification/types`,
        headers: {
          'Content-Type': 'application/json',
        }
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          setNotificationType(res.data.data);
        })
        .catch((error) => {
          console.error(error)
        })
    }
    async function getFinalData() {
      setIsLoading(true);
      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/notifications?`,
        headers: {
          'Content-Type': 'application/json',
         },
        params: {
          'page' :page,
          'limit':'10',
          'type': typeFilter,
          'branch_id': name,
          'group_id': gname,
          'keyword' : searchTerm,
        }
      };
      axios(config)
        .then((res) => {
          setIsLoading(false);
          // console.log(res.data);
          setData(res.data.data);
        })
        .catch((error) => {
          // setIsLoading(false);
          console.error(error)
        })
    }
    getFinalData();
    getNotificationType();
  }, [typeFilter, name, gname, page, searchTerm ]);

  const addNewHandler = (event) => {
    setIsLoading(true);
  }
  

  function showNotifications(event){
    // setGname('');
    // setName('');
  }
  function setTabValue(data){
    setTypeFilter(data);
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const pageHandler = (event, value) => {
    setPage(value);
  };

  return (
    <Wrapper>
      <div className={classes.header}>
        <p className={classes.mainHeading}>Messages {isLoading && <CircularProgress size={10} />}<span style={{color: '#4B2AF1'}}>{totalMessages}</span></p>
        <div className={classes.buttonGroup}>
          {/* <ThemeProvider theme={theme}> */}
            <Button
              component={Link}
              to="/add_new"
              variant='contained'
              onClick={(event)=>addNewHandler()}
              className={classes.headerButton}
              color='primary'
              startIcon={<AddOutlinedIcon />}>
              New Message
            </Button>
          {/* </ThemeProvider> */}
        </div>
      </div>
      <div className={classes.listContainer} >
      <TabContext value={typeFilter.toString()}>
        <div className={classes.header}>
          <div className={classes.headerMessageContainer}>
            <div className={classes.notificationTypes}>
              
                <TabList onChange={showNotifications}>
                  <Tab 
                    label="All" 
                    value="0" 
                    className={classes.notificationTypeFrame} 
                    onClick={(event) => setTabValue(0)} 
                  />
                  {notificationType.map((nType,id) => (
                  <Tab 
                    label={nType.title}
                    value={nType.id.toString()}
                    key={id}  
                    onClick={(event) => setTabValue(nType.id)}
                    className={classes.notificationTypeFrame} 
                  />
                ))}
                </TabList>
              </div>
          </div>
          <div className={classes.searchBoxContainer}>
            <div className={classes.searchFilterContainer}>
              <TextField
                fullWidth
                label="Search"
                onChange={event => {setsearchTerm(event.target.value)}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              ></TextField>
            </div>
            {/* <ThemeProvider theme={theme}> */}
              <Button 
                variant="outlined" 
                color='tertiary' 
                onClick={handleClick} 
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} 
              >
              <FiIcons.FiFilter/>
              </Button>
              <Filter 
                open={open} 
                setAnchorEl={setAnchorEl} 
                anchorEl={anchorEl} 
                id={id}
                branch={branch}
                setBranch={setBranch}
                group={group}
                setGroup={setGroup}
                name={name}
                setName={setName}
                gname={gname}
                setGname={setGname}
              />
            {/* </ThemeProvider> */}
          </div>
        </div>
        <TabPanel value={typeFilter.toString()}>
        {isLoading && <CircularProgress />}
        {data.length!==0 ?
          <Posts 
            data={data} 
            searchTerm={searchTerm}
            isLoading={isLoading}
            typeFilter={typeFilter}
            setTotalMessages={setTotalMessages}
            totalMessages={totalMessages}
          /> : 
            <div style={{ display: 'flex', marginLeft:'45%'}} ><div className={classes.mainHeading}>No results</div></div>}
        </TabPanel>
        {/* <ThemeProvider theme={theme}> */}
          <Pagination 
            className={classes.pagination}
            color='secondary'
            count={5}
            // variant="outlined"
            shape='rounded'
            page={page}
            onChange={pageHandler} 
          />
        {/* </ThemeProvider> */}
      </TabContext>
      </div>
    </Wrapper>
  )
}

export default NotificationListView;
