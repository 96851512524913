import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from './components/layout/header';
import CreateNotification from './pages/addNew/createNotification';
import NotificationListView from './pages/listView/notificationList';
import NotificationDetail from './pages/detailView/notificationDetail';
import EditNotification from './pages/editView/editNotification';
import Login from './pages/login/login';
import ProtectedRoutes from './helpers/protectedRoutes';
import SuccessBar from './components/snackBar';
// import routes from './Routes';

function App() {
  let location = useLocation();
  return (
    // <Router>
      <section className="App">
      {location.pathname!=='/' && <Header />}
        <Routes>
            <Route exact path="/" strict element={<Login />} />
            <Route exact path="/notification/lists" element={<ProtectedRoutes><SuccessBar><NotificationListView /></SuccessBar></ProtectedRoutes>} />
            <Route exact path="/add_new" element={<ProtectedRoutes><SuccessBar><CreateNotification /></SuccessBar></ProtectedRoutes>} />
            <Route exact path="/notification/detail" element={<ProtectedRoutes><NotificationDetail /></ProtectedRoutes>} />
            <Route exact path="/notification/edit" 
              element={<ProtectedRoutes><SuccessBar><EditNotification/>
                        </SuccessBar></ProtectedRoutes>} />
            
        </Routes>
      </section>
    // </Router>
  );
}

export default App;
