import React from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import classes from '../components/commonStyle/createNotification.module.css';

const Editor = (props) => {
    
    const{description, setDescription} = props;
    // const { convert } = require('html-to-text');

    const descriptionHandler = (event, editor) => {
        const data = editor.getData()
        setDescription(data);
        // console.log(data);
            // console.log( { event, editor, data });
        }
        return(
        <div className={classes.richTextArea}>
            <CKEditor
                editor={ ClassicEditor }
                data={description}
                onChange={descriptionHandler}
                required={true}
            />
        </div>
        )
           
        
}

export default Editor;