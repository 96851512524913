import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import reportWebVitals from './reportWebVitals';
import axios from 'axios';

// axios.defaults.baseURL = 'http://192.168.1.2:5000';
// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
  Object.assign(request.headers,{Authorization : 'Bearer '+localStorage.getItem('notification_token')},{'Lync-Token' : 'Bearer '+localStorage.getItem('lync_token')})
    // console.log(request);
    // Edit request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    // console.log(response);
    // Edit response config
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#4B2AF1',
    },
    secondary: {
      main: '#1A122B',
    },
    tertiary: {
      main: '#acabb0',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      textTransform: "unset !important",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
