import React, { useEffect } from 'react';
import { Typography, Box, TextField, Popover, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classes from './filterModal.module.css';
import axios from 'axios';
require('dotenv').config()

const Filter = (props) => {

    const { open, anchorEl, setAnchorEl, id, branch, setBranch, group, setGroup, name, setName, gname, setGname } = props;
   

    async function getBranchData() {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/lync/branches`,
            headers: {
                'Content-Type': 'application/json',
            }
        };
        axios(config)
            .then((res) => {
                // console.log(res.data.data);
                setBranch(res.data.data);
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const branchHandler = (event) => {
        setGname('');
        setName(event.target.value);
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/lync/groups/branch/` +event.target.value,
            headers: {
                'Content-Type': 'application/json',
            }
        };
        axios(config)
            .then((res) => {
                // console.log(res.data.data);
                setGroup(res.data.data);
            })
            .catch((error) => {
                console.error(error)
            })
        
    }

    const groupHandler = (event) => {
        setGname(event.target.value);
    }

    

    useEffect(() => {
        getBranchData();
    }, []);
    
    const handleClose = () => {
        setAnchorEl(null);
        // setGname('');
        // setName('');
      };

    //   console.log(gname);

    return(
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
            <Box className={classes.popover}>
                <div id="modal-modal-title" className={classes.header}>
                    <Typography className={classes.heading}>
                        Filter
                    </Typography>
                    {/* <IconButton className={classes.closeButton}> */}
                        <CloseIcon onClick={handleClose} className={classes.closeButton} />
                    {/* </IconButton> */}
                </div>
                <div className={classes.header} style={{ order: '1' }}>
                    <TextField
                        label='Branch'
                        style={{ order: '0' }}
                        select
                        value={name}
                        onChange={branchHandler}
                        fullWidth
                    >
                         {branch.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                            {option.name}
                            </MenuItem>
                        ))}
                        </TextField>
                    <TextField
                        label='Group'
                        style={{ order: '1' }}
                        select
                        value={gname}
                        onChange={groupHandler}
                        fullWidth
                    >
                        {group.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                            {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </Box>
        </Popover>
    )
}

export default Filter;