import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../../components/commonStyle/createNotification.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CircularProgress, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import './notificationDetail.css';
import '../../components/commonStyle/target.css';
import Wrapper from '../../components/card'; 
import parse from 'html-react-parser';
import { Box, Typography } from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
// import { data } from 'browserslist';
import moment from 'moment';
import * as SiIcons from 'react-icons/si'; 
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BackButton from '../../components/back';
require('dotenv').config()

const theme = createTheme({
    palette: {
      primary: {
        main: '#4B2AF1',
      },
      secondary: {
        main: '#F0F0F0',
      },
      tertiary: {
        main: '#acabb0',
      },
    },
    typography: {
      allVariants: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '22px',
        textTransform: "unset !important",
      },
    },
  });

const NotificationDetail = () => {

        const [activeKey, setActiveKey] = useState(false);
        const [open, setOpen] = useState(false);
        const [singleNotification, setSingleNotification] = useState([]);
        const [target, setTarget] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const [typeName, setTypeName] = useState('');
        const [typeId, setTypeId] = useState('');
        const [response, setResponse] = useState([]);
        const [viewCount, setViewCount] = useState('0');
        const [error, setError] = useState('');
        const [status, setStatus] = useState('');
        const [dateTime, setDateTime] = useState('');

        const tableRef = useRef(null);

        let navigate = useNavigate();
        const color = '#2034F1';

        const handleIconExpand = () => {
            setActiveKey(true);
            setOpen(true);
        }

        const handleIconCollapse = () => {
            setActiveKey(false);
            setOpen(false);
        }

        useEffect(() => {
            async function getSingleNotification() {
              setIsLoading(true);
              var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/notifications/` +localStorage.getItem("id_notification"),
                headers: {
                  'Content-Type': 'application/json',
                }
              };
              axios(config)
                .then((res) => {
                    setIsLoading(false);
                //   console.log(res.data.data);
                  setSingleNotification(res.data.data);
                  setTarget(res.data.data.targets);
                  setTypeId(res.data.data.type_id);
                  if(res.data.data.scheduled_at===null){
                    setStatus('Sent')
                    setDateTime(res.data.data.updatedAt);
                  }
                  else{
                    setStatus('Scheduled At')
                    setDateTime(res.data.data.scheduled_at);
                  }
                  localStorage.setItem("id_notification", res.data.data.id);
                //   setTotalMessages(res.data.data.length);
    
                })
                .catch((error) => {
                  console.error(error)
                })
            }
            
            async function getNotificationType() {
                setIsLoading(false);
                var config = {
                  method: 'get',
                  url: `${process.env.REACT_APP_BASE_URL}/notification/types`,
                  headers: {
                    'Content-Type': 'application/json',
                  }
                };
                axios(config)
                  .then((res) => {
                    res.data.data.map((obj) => { 
                        if(typeId.toString()===obj.id.toString()){
                              setTypeName(obj.title);
                        }
                      })
                  })
                  .catch((error) => {
                    console.error(error)
                  })
            }
            getSingleNotification();
            getNotificationType();
           
          }, [typeId]);

          useEffect(() => {
            async function getResponse() {
                setIsLoading(true);
                var config = {
                  method: 'get',
                  url: `${process.env.REACT_APP_BASE_URL}/lync/responses/` +localStorage.getItem("id_notification"),
                  headers: {
                    'Content-Type': 'application/json',
                  }
                };
                axios(config)
                  .then((res) => {
                        setIsLoading(false);
                        // console.log(res.data);
                        setResponse(res.data.data);
                        setViewCount(res.data.data.length);
                  })
                  .catch((error) => {
                    console.error(error)
                    // console.log(error.response.data.data);
                    if(error.response.data.data.length>100){
                        setError("No Results")
                    }
                    else{
                        setError(error.response.data.data);
                    }
                  })
              }    
              getResponse();       
          }, [])

    return(
        <Wrapper>
            <BackButton />
            <div className={classes.header} style={{borderBottom: "1px solid #D9D8DD", marginBottom:"2%", display:'inline-flex', alignItems:'center'}}>
                <div style={{display: 'flex', flexDirection:'row', marginTop:'1%', color:color}}>
                    <div className={classes.mainHeading} style={{color:color}}>{isLoading && <CircularProgress size={15} />}{singleNotification.title}
                    </div>
                    <div style={{cursor:'pointer'}}>
                        {!open ? <ExpandMoreIcon onClick={() => handleIconExpand()} /> : <ExpandLessIcon onClick={()=>handleIconCollapse()}/>}
                    </div>
                </div>
                {(singleNotification.status === 'draft' || (singleNotification.status === 'active' && singleNotification.scheduled_at!==null)) && 
                    <div style={{display: 'flex', flexDirection:'row', cursor:'pointer', marginTop:'1%', color:color}} onClick={() => navigate("/notification/edit")} >
                        <div>Edit</div>
                        <div><EditIcon fontSize="small" /></div>
                    </div>
                }
                
            </div>
                {activeKey && (
                    <div>
                        {parse(singleNotification.description)}
                    {/* {singleNotification.description.replace(/<[^>]+>/g, '')} */}
                    </div>
                )}
            

            <div className={classes.details} style={{marginTop:"2%"}}>
                <div className="messageType">
                    <div className="titleFrame">
                        <div className="subHeading" >Message Type</div>
                        {isLoading && <CircularProgress size={15} />}
                        <div className="titleDetail" >{typeName}</div>
                    </div>
                    <div className="titleFrame">
                        <div className="subHeading" >{status}</div>
                        <div className="titleDetail" >{moment(dateTime).format("MM/DD/YYYY hh:mm a")}</div>
                    </div>
                </div>
                <div className="analyticsFrame">
                    <div className="analyticContent">
                        <div className="subheading_2">Target Audience</div>
                        <div className="numberData">0</div>
                    </div>
                    <div className="analyticContent">
                        <div className="subheading_2">Message Received</div>
                        <div className="numberData">{viewCount}</div>
                    </div>
                    <div className="analyticContent">
                        <div className="subheading_2">Message Read</div>
                        <div className="numberData">{viewCount}</div>
                    </div>
                </div>
            </div>
            <div className="branchGroupFrame">
            {isLoading && <CircularProgress />}
                {target.map((obj) => {
                            return(
                                <div className="singleBranchFrame" key={obj.branch_id}>
                                    <div className="subheading_2">{obj.branch}</div>
                                    <div className='user-group-frame'>  
                                        {obj.groups.map((el) => {
                                        return(
                                            <Fragment key={el.group_id}>
                                            
                                            <div className='user-single-frame'>
                                                <div className='user-group-name' >{el.group}</div>
                                            </div>
                                            </Fragment>
                                        );
                                    })}
                                    </div>
                                </div>
                            )
                        })}
                   
            </div>

            <Box
                sx={{
                    height:400,
                    width:'100%',
                    order:2
                }}
            >
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:"3%", marginBottom:5, }}>
                    <Typography
                        variant='h6'
                        component='h6'
                        sx={{textAlign:'left', color:color}}
                    >
                        Recipients
                    </Typography>
                    {response.length!==0 &&
                    <DownloadTableExcel
                        filename="Recipients table"
                        sheet="users"
                        currentTableRef={tableRef.current}
                    >
                        <ThemeProvider theme={theme}>
                            <Button
                                variant="contained"
                                color='secondary'
                                endIcon={<SiIcons.SiMicrosoftexcel/>}
                            > 
                                Export 
                            </Button>
                        </ThemeProvider>
                    </DownloadTableExcel>}
                </div>        
                <table className={classes.notificationListGroup} ref={tableRef}>
                    <thead>
                    <tr className={classes.tableRow}>
                        <td className={classes.titleData} ><div className={classes.titleStyle}>Name</div></td>
                        <td className={classes.titleData} ><div className={classes.titleStyle}>Branch</div></td>
                        <td className={classes.statusData} ><div className={classes.titleStyle}>Group</div></td>
                        <td className={classes.titleData} ><div className={classes.titleStyle}>Status</div></td>
                    </tr>
                    </thead>
                {/* {isLoading && <CircularProgress />} */}
                {response.length!==0 && response.map((obj,indexx) => {
                    let notification_status = '';
                    let branch = '';
                    let group = '';
                    target.map((object) => {
                        if(obj.branch_id===object.branch_id){
                            branch=object.branch;
                        }
                        object.groups.map((el) => {
                            if(obj.group_id===el.group_id){
                                group=el.group;
                            }
                        })
                    })
                    if(obj.response===null){
                        notification_status='Viewed';
                    }
                    else{
                        notification_status='Responded';
                    }
                    return(
                        <tbody key={obj.id}>
                            <tr className={classes.tableRow} >
                                <td className={classes.titleData} ><div>{obj.user_name}</div></td>
                                <td className={classes.titleData} ><div>{branch}</div></td>
                                <td className={classes.statusData} ><div>{group}</div></td>
                                <td className={classes.titleData} ><div>{notification_status}</div><div className={classes.descriptionStyle}>{moment(obj.createdAt).format("MM/DD/YYYY hh:mm a")}</div></td>
                            </tr> 
                        </tbody> 
                    )
                })}
                </table>
                {response.length===0 &&
                    <div style={{ display: 'flex', marginLeft:'40%'}} >
                        <div className={classes.mainHeading}>{error}</div>
                    </div>
                }
            </Box>
        </Wrapper>
    );
}

export default NotificationDetail;