import React, { useEffect, useState, Fragment } from 'react';
import Select from '@mui/material/Select';
import { CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import '../../components/commonStyle/target.css';
import classes from '../../components/commonStyle/createNotification.module.css';
import CloseIcon from '../../components/closeIcon';
import axios from 'axios';
require('dotenv').config()


export let target =[];


const TargetAudienceEdit = (props) => {

    const [branch, setBranch] = useState([]);
    const [branchSelect, setBranchSelect] = useState('');
    const [userGroup, setUserGroup] = useState([]);
    const [group, setGroup] = useState([]);
    const [groupLabel, setGroupLabel] = useState([]);
    const [branchLabel, setBranchLabel] = useState('');
    const[isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const { targetArr } = (props);
    let groupData=[];
  


    useEffect(() => {
        async function doGetBranch() {
        setIsLoading(true);
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/lync/branches`,
            headers: {
              'Content-Type': 'application/json',
           }
          };
          axios(config)    
            .then((res) => {
                setIsLoading(false);
                // console.log(res.data.data);
                setBranch(res.data.data);
            })
            .catch((error) => {
                console.error(error)
            })
        }
        doGetBranch();
    }, []);


    const branchHandler = (event, name) => {
       
        setGroup([]);
        setGroupLabel([]);

        setBranchSelect(event.target.value);
        // branchSelect.push(event.target.value);  
        setBranchLabel(name.props.name);
        // branchLabel.push(name);
        setIsLoading(true);
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/lync/groups/branch/` +event.target.value,
            headers: {
              'Content-Type': 'application/json',
            }
          };
          axios(config)  
            .then((res) => {
                setIsLoading(false);
                // console.log(res.data.data);
                setUserGroup(res.data.data);
            })
            .catch((error) => {
                console.error(error)
            })
        }

        const userGroupHandler = (event, name) => {


            setGroup(event.target.value);
            groupLabel.push(name.props.name);
            
        };

        function groupClick(){
            
            if(group.length===0){
                // console.log('nodisable')
                setIsDisabled(false)
             }
            else{
                // console.log('show')
            }
        }

        function removeGroup(data, label){
           
            setGroupLabel([]);
            setGroup([]);
            targetArr.map((object, indx)=>{
                return(
                object.groups.map((ele, index) =>{
                    // console.log(object.groups.length)
                    // console.log(ele.group_id);
                    if (ele.group_id === data) {
                        // console.log('@@@');
                        object.groups.splice(index, 1);
                        
                        if(object.groups.length === 0){
                            targetArr.splice(indx, 1);
                       
                        }
                        else{
                            addTarget(object);
                        }
                    }           
                }));
                }
            );
        }
        for(let i=0; i<group.length; i++){
            groupData.push({group_id: group[i], group: groupLabel[i]});
        }
        let final ={};
        final["branch_id"] = branchSelect;
        final["branch"] = branchLabel;
        final["groups"] = [...groupData];
        addTarget(final)

        function addTarget(data)
        {
            if(data.groups.length>0)
            {
                targetArr.map((object, index)=>{
                    if(object.branch_id===data.branch_id)
                    {
                        //deleting item on index
                        targetArr.splice(index, 1)
                        // console.log("***")
                    }
                })
                targetArr.push(data)
            }
            else{
                // console.log(targetArr);
            }
            
        }

    return (
        <div className='right-frame'>
            <div className='target-audience'>
                <div className='heading'>Target Audience</div>
                <div className='select-frame'>
                    <label className={classes.subHeading}>Branch</label>
                    <Select
                        id= 'select1'
                        labelId="label"
                        size='medium' 
                        className={classes.selectBox}
                        value={branchSelect}
                        onChange={branchHandler}     
                     >
                      {isLoading && <CircularProgress />}
                         {branch.map((branches, id) => (
                            //  <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem key={branches.id} value={branches.id} name={branches.name} >{branches.name}</MenuItem>
                         ))} 
                            
                        
                    </Select>
                </div>

                <div className='select-frame'>
                    <label className={classes.subHeading}>User Group</label>
                    <Select
                        size='medium'
                        value={group}
                        multiple
                        onChange={userGroupHandler}  
                        className={classes.selectBox} 
                        onClick={groupClick} 
                               
                    >
                    {isLoading && <CircularProgress />}
                        {userGroup.map((userGroups, groupid) => (
                            <MenuItem 
                                key={userGroups.id} 
                                value={userGroups.id} 
                                name={userGroups.name} 
                                disabled={isDisabled}
                            >
                                {userGroups.name}
                            </MenuItem>
                        ))}
                        
                       
                                                     
                    </Select>
                    { <div className='display-target'>
                            {targetArr.map((ele, id1) => {
                                return(
                                <div key={ele.branch_id} >
                                    <span className='branch-name' >{ele.branch}</span>
                                    <div className='user-group-frame'>  
                                        {ele.groups.map((el, id2) => {
                                        return(
                                            <Fragment key={el.group_id} >
                                            
                                            <div className='user-single-frame' >
                                            <div className='user-group-name'>{el.group}</div>
                                            <div className='user-close-icon' value={ele.group_id} onClick={(event) => removeGroup(el.group_id, el.group)}>
                                                <CloseIcon className='close-icon' value={ele.group_id}/>
                                            </div>
                                            </div>
                                            </Fragment>
                                        );
                                    })}
                                    </div>
                                </div>
                                );
                            })}
                            
                        </div>
                    }
                </div>      
            </div>
        </div>          
    );
}

export default TargetAudienceEdit;