import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FormControlLabel, TextField, Radio, RadioGroup, Checkbox, FormGroup, CircularProgress } from '@mui/material';
import classes from '../../components/commonStyle/createNotification.module.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Editor from '../../components/editor';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CalenderIcon from '../../components/calenderIcon';
import '../../components/commonStyle/target.css';
import TargetAudience from './target';
// import { target } from '../../components/target';
import axios from 'axios';
import Wrapper from '../../components/card';
import BackButton from '../../components/back';
require('dotenv').config()


const theme = createTheme({
    palette: {
      primary: {
        main: '#4B2AF1',
      },
      secondary: {
        main: '#F0F0F0',
      },
    },
    components: {
        // Name of the component
        MuiFormControl: {
          styleOverrides: {
            // Name of the slot
            root: {
              // Some CSS
              flexDirection: 'row',
            },
          },
        },
      }, 
      typography: {
        allVariants: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '22px',
          textTransform: "unset !important",
        },
      },
  });
const CreateNotification = (props) => {

        let navigate = useNavigate();
        const [notificationType, setNotificationType] = useState([]);
        const [type_id, setTypeId] = useState('1');
        const [title, setTitle] = useState('');
        const [description, setDescription] = useState('');
        const [checked, setChecked] = useState(false);
        const [status, setStatus] = useState('');
        const [scheduled_at, setScheduledAt] = useState();
        const [targetArr, setTargetArr] = useState([]);
        const [isLoading, setIsLoading] = useState(false);

        const { state, setState } = props;

          useEffect(() => {
            async function getNotificationType() {  
                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_BASE_URL}/notification/types`,
                    headers: {
                      'Content-Type': 'application/json',
                    }
                  };
                  axios(config)       
                    .then((res) => {
                        // setIsLoading(false);
                        // console.log(res.data.data);
                        setNotificationType(res.data.data);
                    })
                    .catch((error) => {
                        console.error(error)
                })
            }
            getNotificationType();
        }, []);
    

        const radioButtonHandler = (event) => {
            setTypeId(event.target.value);
    
            // console.log(event.target.value);
        }

        const titleHandler = event => {
            setTitle(event.target.value);
        }

        const handleChange = (event) => {
            setChecked(event.target.checked);
        };

        const dateHandler= (newValue) => {
            setScheduledAt(newValue);
            console.log(newValue);
          };

        async function sendData(event) {
            setIsLoading(true);
            event.preventDefault(); 
            let data = {};  
            // console.log(scheduled_at);
            if(scheduled_at!==undefined){
                data ={
                    "type_id" : type_id,
                    "targets" : targetArr,
                    "title" : title,
                    "description" : description,
                    "scheduled_at" : scheduled_at,
                    "response_type" : "2",
                    "status" : status
                }
            }
            else{
                data = {
                    "type_id" : type_id,
                    "targets" : targetArr,
                    "title" : title,
                    "description" : description,
                    "response_type" : "2",
                    "status" : status
                }
            }
            
            
            var config = {
              method: 'post',
              url: `${process.env.REACT_APP_BASE_URL}/notifications`,
              headers: {
                  'Content-Type': 'application/json',
                  "Authorization" : "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGNlbGxvLmNvbSIsIm9yZ2FuaXphdGlvbl9pZCI6IjIiLCJpYXQiOjE2NjEzNDEyODMsImV4cCI6MTY5Mjg3NzI4M30.k1Cdc9oe8BSMwa0_dwMVIk7yTB5xRLAUTjQg6StkjTQ"
              },
              data : data
            };
            axios(config)
            .then((res) => {
                setIsLoading(false);
                // console.log(res.data);
                if(res.data.status==='success'){
                    setState({ ...state, openSnackbar: true, success: true });
                    // console.log('notification created');
                    navigate("/notification/lists");
                }
            })
            .catch((error) => {
                setState({ ...state, openSnackbar: true, success: false, message: "Notification is not created" });
                   
                setIsLoading(false);
                console.error(error)
                // console.log('not created');
            })
        }
        
    return(
        <Wrapper>
            <BackButton />
            <form onSubmit={sendData} style={{width:'100%'}}>
                <div className={classes.header}>
                    <p className={classes.mainHeading}>Create a message</p>
                
                    <div className={classes.buttonGroup}>
                        <ThemeProvider theme={theme}>
                        <Button variant='contained' color="secondary" className={classes.headerButton} type="submit" onClick={()=>{setStatus('draft'); setState({...state, message: "Notification saved as draft"})}}>Save as draft {isLoading && <CircularProgress size={15} style={{color:'#FFFFFF'}}/>}</Button>
                        <Button variant='contained' color='primary' className={classes.headerButton} type="submit" onClick={()=>{setStatus('active'); setState({...state, message: "Notification sent"})}} >Send now {isLoading && <CircularProgress size={15} style={{color:'#FFFFFF'}}/>}</Button>
                        </ThemeProvider>
                    </div>
                </div>
                <div style={{marginTop:'2%'}}>
                    <ThemeProvider theme={theme}>
                        <div className={classes.formControlStyle}>
                            <div className={classes.left}>
                                <div className={classes.messageType}>
                                    <label name='message_type' className={classes.subHeading}>Message type</label>
                                        <RadioGroup
                                            row
                                            name='row-radio-button-group'
                                            className={classes.notificationTypes}
                                            value={type_id}
                                            onChange={radioButtonHandler}>
                                            {notificationType.map((nType, id) => (
                                                <FormControlLabel key={id} value={nType.id} control={<Radio required={true} />} label={nType.title} className={classes.radioButton} />
                                            ))}
                                        </RadioGroup>
                                </div>
                                <div className={classes.messageTitle} style={{width:'100%'}}>
                                    <label name='message_title' className={classes.subHeading}>Message Title</label>
                                    {/* <div className={classes.titleFrame}> */}
                                        <FormGroup>
                                            <TextField 
                                                id="outlined-basic" 
                                                variant="outlined"
                                                type='text'
                                                name='message_title'
                                                // className={classes.textField}
                                                value={title}
                                                onChange={titleHandler}
                                                required={true}
                                            />
                                        </FormGroup>
                                    {/* </div> */}
                                </div>
                                <Editor description={description} setDescription={setDescription}/>
                                <div className={classes.schedule}>
                                    <div className={classes.checkbox}>
                                        <div className={classes.checkboxframe}>
                                            <Checkbox 
                                                checked={checked}
                                                onChange={handleChange}
                                                value={status}
                                            />
                                        </div>
                                        <label>Schedule</label>
                                    </div>
                                    {checked &&
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker 
                                                renderInput={(props) => <TextField {...props} />}
                                                components={{
                                                    OpenPickerIcon: CalenderIcon
                                                }}
                                                format= "yyyy-mm-dd HH:mm"
                                                inputFormat="yyyy-MM-dd HH:mm"
                                                ampm={false}
                                                value={scheduled_at}
                                                minDate={new Date()}
                                                onChange={(dateHandler)}
                                            />  
                                        </LocalizationProvider>
                                    }
                                </div>
                            </div>
                            <TargetAudience targetArr={targetArr}/>
                        </div> 
                    </ThemeProvider>
                         
                </div>    
            </form>
        </Wrapper>      
    )
}

export default CreateNotification;