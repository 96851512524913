import React, { useEffect } from 'react'
import classes from '../../components/commonStyle/createNotification.module.css';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
require('dotenv').config()


const theme = createTheme({
    palette: {
      primary: {
        main: '#4B2AF1',
      },
      secondary: {
        main: '#F0F0F0',
      },
      tertiary: {
        main: '#686868',
      },
    },
    typography: {
      allVariants: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '22px',
        textTransform: "unset !important",
      },
    },
  });


const Posts = (props) => {

    const{data, typeFilter, setTotalMessages } = props;
    // const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
      async function getFinalData() {
        // setIsLoading(true);
        var config = {
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/notifications?`,
          headers: {
            'Content-Type': 'application/json',
           },
          params: {
            'page' :'1',
            'limit':'10',
            'type': typeFilter,
          }
        };
        axios(config)
          .then((res) => {
            // console.log(res.data);
            setTotalMessages(res.data.data.length);
            // totalMessage=res.data.data.length;
          })
          .catch((error) => {
            // setIsLoading(false);
            console.error(error)
          })
      }
      getFinalData();
    }, [])

    // console.log(totalMessages);
    
    function viewButtonHandler(data){
      // console.log(data);
      localStorage.setItem("id_notification", data);
      navigate("/notification/detail");
    }

    function editButtonHandler(data){
      localStorage.setItem("id_notification", data);
      navigate("/notification/edit");
    }
     

    return(
        <table className={classes.notificationListGroup}>
          {data.map((obj,indexx) => {
            // if(obj.type_id.includes(typeFilter)){
              let description = obj.description;
              let plainText = description.replace(/<[^>]+>/g, '');
              let display_description = plainText.replace(/((&nbsp;))*/gmi, '');
              let notification_status = '';
              if(obj.status==='active' && obj.scheduled_at===null){
                notification_status='Delivered';
                // console.log('1st');
              }
              else if(obj.status==='draft'){
                notification_status='Draft';
                // console.log('2nd');
              }
              else if(obj.status==='active' && obj.scheduled_at!==null){
                notification_status='Scheduled';
                // console.log('4th');
              }
              return(
                  <tbody key={obj.id}>
                        <tr className={classes.tableRow} >
                            <td className={classes.titleData} ><div className={classes.titleStyle} >{obj.title}</div><div className={classes.descriptionStyle} >{display_description.substring(0,40)}</div></td>
                            <td className={classes.statusData} ><div className={classes.titleStyle} >{notification_status}</div>{notification_status==='Scheduled' && (<div className={classes.descriptionStyle} >{moment(obj.scheduled_at).format("MM/DD/YYYY hh:mm a")}</div>)}</td>
                            <td className={classes.actionButtonGroups} >
                                <ThemeProvider theme={theme}>
                                  {notification_status!=='Draft' &&
                                    <Button variant='contained' color='secondary' onClick={(event)=>viewButtonHandler(obj.id)} >View</Button> }
                                  {(notification_status==='Draft' || notification_status==='Scheduled') && (
                                    <Button variant='outlined' color='tertiary' onClick={(event)=>editButtonHandler(obj.id)} >Edit</Button>
                                  )}
                                </ThemeProvider>
                            </td>
                        </tr> 
                  </tbody> 
              )
          })}
        </table>
    )
}

export default Posts;