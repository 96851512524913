import React, {Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import * as AiIcons from 'react-icons/ai';

const useStyles = makeStyles(() => ({
    backIcon: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px",
      gap: "1vh",
      height: "0.5rem",
      cursor: "pointer",
      flex: "none",
      order: '0',
      flexGrow: '0',
    },
    backText: {
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "22px",
        color: "rgba(0, 0, 0, 0.85)",
        flex: "none",
        order: 1,
        flexGrow: 0,
    },
    
  }));

const BackButton = () => {
    const {backIcon, backText} = useStyles();
    let navigate = useNavigate();
    const style = { left: "16.67%",
        right: "16.67%",
        top: "17.59%",
        bottom: "17.59",
        backGround: "rgba(0, 0, 0, 0.85)"}
    return(
        <Fragment>
            <div onClick={()=>navigate(-1)} style={{ textDecoration: 'none', marginBottom: '5vh' }}>
                <div className={backIcon} >
                    <AiIcons.AiOutlineArrowLeft style={style}/>
                    <p className={backText}>Back</p>
                </div>
            </div>
        </Fragment>
    )
       
    
}

export default BackButton;