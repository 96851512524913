import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';


const useStyles = makeStyles(() => ({
    root: {
      "& .MuiSnackbar-anchorOriginTopRight":{
          opacity:'0'
      }
    }
  }));
const SuccessBar = ({ children }, props) => {


    const {root} = useStyles();
    const [state, setState] = useState({
        vertical: 'top',
        horizontal: 'right',
        success: '',
        openSnackbar: false,
        message: '',
      });
    
      const { vertical, horizontal } = state;
      
    return(
        <Fragment>
            {state.success ? 
                <Snackbar
                    className = {root}
                    anchorOrigin={{ vertical, horizontal }}
                    autoHideDuration={3000}
                    open={state.openSnackbar}
                    onClose={()=> setState({ ...state, openSnackbar: false})}
                    key={vertical + horizontal}
                >
                    <MuiAlert severity="success">
                        {state.message}
                    </MuiAlert>
                </Snackbar> :
                <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={3000}
                open={state.openSnackbar}
                onClose={()=>setState({ ...state, openSnackbar: false})}
                key={vertical + horizontal}
            >
                <MuiAlert severity="error">
                    {state.message}
                </MuiAlert>
            </Snackbar>

            }
            {React.Children.map(children, (child) =>
        React.cloneElement(child, { setState, state })
      )}

        </Fragment>
        
    )
    
}

export default SuccessBar;