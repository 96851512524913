import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {

    let navigate = useNavigate();

    useEffect(() => {
        function hasJWT() {
            let flag = false;
    
            //check user has JWT token
            localStorage.getItem("lync_token")&&localStorage.getItem("notification_token") ? flag=true : flag=false
            return flag
        }
        if (!hasJWT()) {
            //   return <Navigate to="/" />;
                navigate("/");
            }
    },[])
    

    
    return children;
};

export default ProtectedRoutes;